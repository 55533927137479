import React, { useLayoutEffect } from "react";
import styled from "styled-components";
import MileStones from "../components/MileStones";

// gsap
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Development = () => {
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    ScrollTrigger.refresh();
    return () => {
      ScrollTrigger.getAll().forEach((t) => {
        t.scroll(0);
        t.kill(true);
      });
    };
  }, []);
  return (
    <Container>
      <Intro className="ratio ratio-16x9">
        <iframe
          src="https://www.youtube.com/embed/yloCJYZm9Co?rel=0"
          title="YouTube video"
          allowFullScreen
        ></iframe>
      </Intro>
      <LatestEvents>
        <div>
          <div>
            <MileStones
              line1="VIEW"
              line3="OUR LATEST"
              line4="EVENTS"
              mg="3rem 0 0.5rem 0rem"
            />
            <div
              className="item"
              style={{
                background:
                  "url(https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/development-events1_qymf7c.png) center / cover",
              }}
            >
              <div className="text">
                <h6>
                  WCL 2021
                  <br />
                  <small>OCTOBER 10TH, 2021</small>
                </h6>
              </div>
            </div>
          </div>
          <div
            className="item"
            style={{
              background:
                "url(https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/development-events2_q7pcev.png) center / cover",
            }}
          >
            <div className="text">
              <h6>
                AIUR MARKETING 2023
                <br />
                <small>OCTOBER 27TH, 2023</small>
              </h6>
            </div>
          </div>
          <div
            className="item"
            style={{
              background:
                "url(https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/development-events3_eskhon.png) center / cover",
            }}
          >
            <div className="text">
              <h6>
                AIUR SOFTWARE 2024
                <br />
                <small>FEB 25TH, 2024</small>
              </h6>
            </div>
          </div>
        </div>
      </LatestEvents>
    </Container>
  );
};

export default Development;

const Container = styled.section`
  background: #00253a;
  padding: 5rem 0 2rem;
`;

const Intro = styled.div`
  margin: 0 auto;
  width: 90%;
  @media screen and (min-width: 768px) {
    width: 35rem;
  }
`;

const LatestEvents = styled.div`
  > div:last-of-type {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: 768px) {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-evenly;
      gap: 0;
    }
  }
  .item {
    width: 18rem;
    position: relative;
    padding-top: 4rem;
    aspect-ratio: 0.7;
    @media screen and (min-width: 768px) {
      width: 13rem;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(0, 42, 67, 0.6);
    }
    > .text {
      position: relative;
      font-family: Inter;
      text-align: center;
      h6 {
        color: #fff;
        line-height: 1.5;
        small {
          color: #c8af7d;
          font-size: 0.8em;
          font-weight: bold;
          letter-spacing: 3px;
        }
      }
    }
  }
`;
