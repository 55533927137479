import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import { RxDoubleArrowDown } from "react-icons/rx";
import { TbArrowBadgeRightFilled } from "react-icons/tb";
import { IoIosStar } from "react-icons/io";
import { IoEye } from "react-icons/io5";
import { MdFilterCenterFocus } from "react-icons/md";

import Modal from "react-bootstrap/Modal";
import MileStones from "../components/MileStones";
import OverlayBtn from "../components/OverlayBtn";
import CrewMobile from "../components/CrewMobile";
import CrewDesktop from "../components/CrewDesktop";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import { EffectCoverflow, Pagination } from "swiper/modules";

// gsap
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const ExploreTheCosmo = () =>
  // {
  //   handleTurnOffAudio,
  //   handleTurnOnAudio
  // }
  {
    const [show, setShow] = useState(false);

    const handleShow = () => {
      // handleTurnOffAudio();
      setShow(true);
    };
    const handleClose = () => {
      // handleTurnOnAudio();
      setShow(false);
    };
    const [crewTit1, setCrewTit1] = useState(null);
    const [crewTit2, setCrewTit2] = useState(null);
    const [crewContent, setCrewContent] = useState(null);
    const [crewImg, setCrewImg] = useState(null);
    const [startVidGame2Ref, setStartVidGame2Ref] = useState(false);
    const vidGame2Ref = useRef();
    const playGame2Ref = useRef(null);

    const handleEndedVidGameTwo = () => {
      // handleTurnOnAudio();
      let tl1 = gsap.timeline();
      tl1.to(vidGame2Ref.current, {
        display: "none",
        duration: 0,
      });
      tl1.to(
        playGame2Ref.current,
        {
          display: "block",
        },
        "<"
      );
      setStartVidGame2Ref(true);
    };
    useLayoutEffect(() => {
      window.scrollTo({ top: 0, behavior: "auto" });
      ScrollTrigger.refresh();
      return () => {
        ScrollTrigger.getAll().forEach((t) => {
          t.scroll(0);
          t.kill(true);
        });
      };
    }, []);
    return (
      <Container>
        <Modal show={show} onHide={handleClose}>
          <ContainerModalGameFirst>
            <iframe
              title="Abandoned Planet"
              src="https://haonguyen7920.github.io/aiur-marketing-game1/"
              style={{
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </ContainerModalGameFirst>
        </Modal>
        <div
          className="modal fade"
          id="modalLetter"
          tabIndex="-1"
          aria-labelledby="modalLetterLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <ContainerModalLetter>
                <svg
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                  }}
                >
                  <polygon
                    points="0 0, 100 0, 100 95, 95 100, 0 100"
                    fill="none"
                    stroke="rgba(251, 188, 4, 0.70)"
                    strokeWidth="1px"
                  />
                </svg>
                <h1>OPEN LETTER</h1>
                <h6>
                  Dear distinguished partners,
                  <br />
                  <br />
                  You are currently holding the 2023 Portfolio of AIUR
                  MARKETING. We are an agency specialized in providing
                  comprehensive marketing solutions for businesses. From brand
                  building, design & creativity, digital marketing, websites,
                  applications, and communication campaigns, to TVC advertising
                  production.
                  <br />
                  <br />
                  To bridge the gap between businesses and customers, AIUR
                  MARKETING consistently emphasizes everything from consulting
                  on implementation to measuring the effectiveness of campaigns,
                  developing creative solutions, and converting potential
                  customers that are suitable for every field.
                  <br />
                  <br />
                  With the desire to become a trusted partner of your esteemed
                  business, we commit to continuously strive and work to bring
                  out the best services.
                </h6>
                <h5>YOURS SINCERELY,</h5>
              </ContainerModalLetter>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="modalCrew"
          tabIndex="-1"
          aria-labelledby="modalCrewLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <ContainerModalCrew>
                <div>
                  <div style={{ width: "45%" }} className="crewText">
                    <h4>{crewTit1}</h4>
                    <h6>({crewTit2})</h6>
                    <p>{crewContent}</p>
                  </div>
                  <img src={crewImg} alt="" />
                </div>
              </ContainerModalCrew>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="modalGameB"
          tabIndex="-1"
          aria-labelledby="modalGameBLabel"
          aria-hidden="true"
          onBlur={() => {
            // handleTurnOnAudio();
            vidGame2Ref.current.pause();
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <ContainerModalGameSecond>
                <video
                  width="100%"
                  height="100%"
                  style={{
                    objectFit: "cover",
                    display: "block",
                  }}
                  ref={vidGame2Ref}
                  onEnded={() => {
                    handleEndedVidGameTwo();
                  }}
                >
                  <source
                    src="https://res.cloudinary.com/db85dpvaz/video/upload/aiur-marketing/game2Vid_spxkpz.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
                <div
                  ref={playGame2Ref}
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "none",
                  }}
                >
                  <iframe
                    title="Abandoned Planet"
                    src="https://haonguyen7920.github.io/aiur-marketing-game2/"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  ></iframe>
                </div>
              </ContainerModalGameSecond>
            </div>
          </div>
        </div>
        <SectionA>
          {/* mobile */}
          <button
            className="verMobile"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasLetter"
            aria-controls="offcanvasLetter"
          >
            OPEN LETTER
          </button>
          <div
            className="offcanvas offcanvas-bottom"
            tabIndex="-1"
            id="offcanvasLetter"
          >
            <div className="offcanvas-header">
              <h6 className="offcanvas-title">OPEN LETTER</h6>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ContainerModalLetter>
                <h6>
                  Dear distinguished partners,
                  <br />
                  <br />
                  You are currently holding the 2023 Portfolio of AIUR
                  MARKETING. We are an agency specialized in providing
                  comprehensive marketing solutions for businesses. From brand
                  building, design & creativity, digital marketing, websites,
                  applications, and communication campaigns, to TVC advertising
                  production.
                  <br />
                  <br />
                  To bridge the gap between businesses and customers, AIUR
                  MARKETING consistently emphasizes everything from consulting
                  on implementation to measuring the effectiveness of campaigns,
                  developing creative solutions, and converting potential
                  customers that are suitable for every field.
                  <br />
                  <br />
                  With the desire to become a trusted partner of your esteemed
                  business, we commit to continuously strive and work to bring
                  out the best services.
                </h6>
                <h5>YOURS SINCERELY,</h5>
              </ContainerModalLetter>
            </div>
          </div>

          {/* desktop */}
          <button
            className="verDesktop"
            data-bs-toggle="modal"
            data-bs-target="#modalLetter"
          >
            OPEN LETTER
          </button>
          <div className="scrollDown">
            <h5>
              Scroll
              <br />
              Down
            </h5>
            <RxDoubleArrowDown />
          </div>
        </SectionA>
        <MileStones line1="01" line2=".24" line3="ABOUT" line4="US" />
        <SectionB>
          <Text>
            FROM AN IN-HOUSE MARKETING TEAM, AIUR MARKETING HAS EMBARKED ON A
            CHALLENGING JOURNEY TO BECOME ONE OF THE LEADING PROFESSIONAL
            AGENCIES IN MARKETING.
            <br />
            <br />
            WE UNDERSTAND THE DIFFICULTIES AND CHALLENGES THAT COMPANIES AND
            BUSINESSES ARE FACING. WITH VALUES NURTURED FROM PASSION AND
            ENTHUSIASM, TOGETHER WITH AN EXPERIENCED AND DEDICATED TEAM, WE
            BELIEVE THAT WE CAN OVERCOME ALL OBSTACLES ALONGSIDE YOUR BUSINESS.
            WE, OURSELVES, UNDERSTAND THAT SUCCESS ONLY COMES WHEN EVERYONE
            LOOKS IN THE SAME DIRECTION.
            <br />
            <br />
            AIUR MARKETING ALWAYS KEEPS UP WITH THE LATEST MARKETING TRENDS. WE
            ARE NOT AFRAID TO FACE CHALLENGES AND WE WILL CONSTANTLY SEEK NEW
            WAYS TO SOLVE OUR CUSTOMERS' PROBLEMS. THIS REFLECTS OUR COMMITMENT
            TO DELIVERING THE MOST EFFECTIVE MARKETING SOLUTIONS.
            <br />
            <br />
            AFTER YEARS OF BUILDING AND GROWING, AIUR MARKETING HAS BECOME ONE
            OF THE LEADING DIGITAL MARKETING COMPANIES. WE TAKE PRIDE IN
            PROVIDING TAILORED SOLUTIONS THAT HELP BUSINESSES ACHIEVE SUCCESS
            AND BRING THEIR PRODUCT TO THOUSANDS OF CUSTOMERS.
            <br />
            "ABSORB THE SPOTLIGHT, ACCELERATE YOUR BUSINESS" IS THE "MORAL
            COMPASS" THAT WE HAVE CONSISTENTLY USED THROUGHOUT OUR JOURNEY WITH
            OUR DISTINGUISHED CUSTOMERS.
          </Text>
          <h6>
            JIMMY TRAN - VIETNAMESE AMERICAN BUSINESSMAN,
            <br />
            <span style={{ whiteSpace: "nowrap" }}>
              FOUNDER & CEO OF AIUR MARKETING.
            </span>
          </h6>
        </SectionB>
        <MileStones line1="02" line2=".24" line3="OUR" line4="CREW" />
        <SectionC>
          <div className="wrap">
            <h5>
              Human resources are the foundation of a business, and the decisive
              factor in the success of the projects. Understanding this, AIUR
              MARKETING always develops comprehensive and long-term human
              resource development strategies to provide customers with
              experienced, passionate, sharp-eyed personnel who can correctly
              assess issues, and steer the brand in the right direction.
            </h5>
            <Swiper
              effect={"coverflow"}
              centeredSlides={true}
              slidesPerView={"auto"}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              pagination={true}
              modules={[EffectCoverflow, Pagination]}
              className="crewListMobile"
            >
              <SwiperSlide>
                <CrewMobile
                  img="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-crew1_qxyynd.jpg"
                  tit1="Explorer"
                  tit2="Content Creator"
                  content="As the builders of Aiur's cosmos, they gather information and generate ideas, fueling the creative engine of your campaigns."
                  setCrewTit1={setCrewTit1}
                  setCrewTit2={setCrewTit2}
                  setCrewContent={setCrewContent}
                  setCrewImg={setCrewImg}
                />
              </SwiperSlide>
              <SwiperSlide>
                <CrewMobile
                  img="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-crew2_c5cxj7.jpg"
                  tit1="Visionary"
                  tit2="SEO Specialist"
                  content="Focuses on optimizing content created by the Explorer, ensuring maximum visibility and impact."
                  setCrewTit1={setCrewTit1}
                  setCrewTit2={setCrewTit2}
                  setCrewContent={setCrewContent}
                  setCrewImg={setCrewImg}
                />
              </SwiperSlide>
              <SwiperSlide>
                <CrewMobile
                  img="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-crew3_letefi.jpg"
                  tit1="Nexus"
                  tit2="CEO"
                  content="The central command of Aiur Marketing, overseeing and guiding the strategic direction of the company."
                  setCrewTit1={setCrewTit1}
                  setCrewTit2={setCrewTit2}
                  setCrewContent={setCrewContent}
                  setCrewImg={setCrewImg}
                />
              </SwiperSlide>
              <SwiperSlide>
                <CrewMobile
                  img="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-crew4_ilva22.jpg"
                  tit1="Vanguard"
                  tit2="Marketing Leader"
                  content="Overseeing progress and leading the media team, akin to a captain steering the ship."
                  setCrewTit1={setCrewTit1}
                  setCrewTit2={setCrewTit2}
                  setCrewContent={setCrewContent}
                  setCrewImg={setCrewImg}
                />
              </SwiperSlide>
              <SwiperSlide>
                <CrewMobile
                  img="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-crew5_cyptqx.jpg"
                  tit1="Phoenix"
                  tit2="Sales"
                  content="Elevates the team's efforts into successful sales and advertising strategies, driving revenue growth."
                  setCrewTit1={setCrewTit1}
                  setCrewTit2={setCrewTit2}
                  setCrewContent={setCrewContent}
                  setCrewImg={setCrewImg}
                />
              </SwiperSlide>
              <SwiperSlide>
                <CrewMobile
                  img="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-crew6_nemvho.jpg"
                  tit1="Guardian"
                  tit2="Editor"
                  content="Handles recording, filming, and editing with resilience and attention to detail."
                  setCrewTit1={setCrewTit1}
                  setCrewTit2={setCrewTit2}
                  setCrewContent={setCrewContent}
                  setCrewImg={setCrewImg}
                />
              </SwiperSlide>
              <SwiperSlide>
                <CrewMobile
                  img="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-crew7_gr5ac0.jpg"
                  tit1="Carrier"
                  tit2="Manager"
                  content="Coordinates and supports the team, ensuring smooth operations and resource availability."
                  setCrewTit1={setCrewTit1}
                  setCrewTit2={setCrewTit2}
                  setCrewContent={setCrewContent}
                  setCrewImg={setCrewImg}
                />
              </SwiperSlide>
              <SwiperSlide>
                <CrewMobile
                  img="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-crew8_l2h3we.jpg"
                  tit1="Navigator"
                  tit2="Account Marketing"
                  content="Responsible for planning, customer consultation, and monitoring the effectiveness of marketing strategies."
                  setCrewTit1={setCrewTit1}
                  setCrewTit2={setCrewTit2}
                  setCrewContent={setCrewContent}
                  setCrewImg={setCrewImg}
                />
              </SwiperSlide>
            </Swiper>
            <div className="crewListDesktop">
              <CrewDesktop
                img="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-crew1_qxyynd.jpg"
                tit1="Explorer"
                tit2="Content Creator"
                content="As the builders of Aiur's cosmos, they gather information and generate ideas, fueling the creative engine of your campaigns."
                setCrewTit1={setCrewTit1}
                setCrewTit2={setCrewTit2}
                setCrewContent={setCrewContent}
                setCrewImg={setCrewImg}
              />
              <CrewDesktop
                img="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-crew2_c5cxj7.jpg"
                tit1="Visionary"
                tit2="SEO Specialist"
                content="Focuses on optimizing content created by the Explorer, ensuring maximum visibility and impact."
                setCrewTit1={setCrewTit1}
                setCrewTit2={setCrewTit2}
                setCrewContent={setCrewContent}
                setCrewImg={setCrewImg}
              />
              <CrewDesktop
                img="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-crew3_letefi.jpg"
                tit1="Nexus"
                tit2="CEO"
                content="The central command of Aiur Marketing, overseeing and guiding the strategic direction of the company."
                setCrewTit1={setCrewTit1}
                setCrewTit2={setCrewTit2}
                setCrewContent={setCrewContent}
                setCrewImg={setCrewImg}
              />
              <CrewDesktop
                img="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-crew4_ilva22.jpg"
                tit1="Vanguard"
                tit2="Marketing Leader"
                content="Overseeing progress and leading the media team, akin to a captain steering the ship."
                setCrewTit1={setCrewTit1}
                setCrewTit2={setCrewTit2}
                setCrewContent={setCrewContent}
                setCrewImg={setCrewImg}
              />
              <CrewDesktop
                img="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-crew5_cyptqx.jpg"
                tit1="Phoenix"
                tit2="Sales"
                content="Elevates the team's efforts into successful sales and advertising strategies, driving revenue growth."
                setCrewTit1={setCrewTit1}
                setCrewTit2={setCrewTit2}
                setCrewContent={setCrewContent}
                setCrewImg={setCrewImg}
              />
              <CrewDesktop
                img="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-crew6_nemvho.jpg"
                tit1="Guardian"
                tit2="Editor"
                content="Handles recording, filming, and editing with resilience and attention to detail."
                setCrewTit1={setCrewTit1}
                setCrewTit2={setCrewTit2}
                setCrewContent={setCrewContent}
                setCrewImg={setCrewImg}
              />
              <CrewDesktop
                img="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-crew7_gr5ac0.jpg"
                tit1="Carrier"
                tit2="Manager"
                content="Coordinates and supports the team, ensuring smooth operations and resource availability."
                setCrewTit1={setCrewTit1}
                setCrewTit2={setCrewTit2}
                setCrewContent={setCrewContent}
                setCrewImg={setCrewImg}
              />
              <CrewDesktop
                img="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-crew8_l2h3we.jpg"
                tit1="Navigator"
                tit2="Account Marketing"
                content="Responsible for planning, customer consultation, and monitoring the effectiveness of marketing strategies."
                setCrewTit1={setCrewTit1}
                setCrewTit2={setCrewTit2}
                setCrewContent={setCrewContent}
                setCrewImg={setCrewImg}
              />
            </div>
          </div>
          <div
            style={{
              height: "3rem",
              background:
                "linear-gradient(to bottom, rgba(34, 179, 253, 0.2) 0%, #22B3FD 100%)",
              clipPath: "polygon(0 0, 100% 0, 50% 100%)",
            }}
          ></div>
          <a href="https://drive.google.com/uc?export=download&id=1mMyfIVpFlJdweZX9BwkwdcoB7DCo_gcd">
            VIEW OUR CATALOG
          </a>
          <div
            className="offcanvas offcanvas-bottom"
            tabIndex="-1"
            id="offcanvasCrew"
          >
            <button
              type="button"
              className="btn-close position-absolute"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              style={{
                right: "1rem",
                top: "1rem",
              }}
            ></button>
            <div
              className="offcanvas-body"
              style={{
                background: `linear-gradient(
                180deg,
                rgba(71, 72, 59, 0.5) 0%,
                rgba(71, 72, 59, 1) 100%
              ), url(${crewImg}) center / cover`,
                padding: "3rem 1rem",
              }}
            >
              <div className="crewText">
                <h4>{crewTit1}</h4>
                <h6>({crewTit2})</h6>
                <p>{crewContent}</p>
              </div>
            </div>
          </div>
        </SectionC>
        <MileStones
          line1="03"
          line2=".24"
          line3="MISSION, VISION"
          line4="& CORE VALUES"
        />
        <SectionD>
          <div className="article">
            <div className="item para">
              <div className="title">
                <IoIosStar />
                <h3>MISSION</h3>
              </div>
              <Text>
                AIUR MARKETING is an ambitious enterprise. We are not just an
                agency; we are also partners of our clients who turn their
                ideas, dreams, and plans into reality.
              </Text>
              <Text>
                We not only create high-quality products and services but also
                provide valuable solutions that create a milestone on the
                fiercely competitive business journey. Throughout our journey,
                AIUR MARKETING has built trust, success and sustainability in
                both individual clients and businesses.
              </Text>
            </div>
            <div className="item pic">
              <div className="ratio">
                <img
                  src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-mission_cfbuka.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="article">
            <div className="item pic second">
              <div className="ratio">
                <img
                  src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-vision_zkrqwx.jpg"
                  alt=""
                  className="second"
                />
              </div>
            </div>
            <div className="item para">
              <div className="title">
                <IoEye />
                <h3>VISION</h3>
              </div>
              <Text>
                In the future, AIUR MARKETING envisions itself becoming one of
                the top 10 marketing agency companies in the Central Highlands
                region of Vietnam and being in top 30 marketing agency companies
                in California, USA.
              </Text>
              <Text>
                With an open mindset and a readiness to confront new challenges,
                we aim not only to create special value for our clients but also
                to contribute to the development of the community and build a
                more prosperous future for everyone.
              </Text>
              <Text>
                Indeed, success comes to those who seize opportunities and do
                not shy away from challenges. This is the guiding spirit that
                the founder of AIUR MARKETING wants to instill in the core
                values of the company. We will continue to strive, innovate, and
                partner with businesses and clients on this journey.
              </Text>
            </div>
          </div>
          <div className="article">
            <div className="item para">
              <div className="title">
                <MdFilterCenterFocus />
                <h3>CORE VALUES</h3>
              </div>
              <Text>
                <ul style={{ listStyleType: "decimal" }}>
                  <li>
                    PERSON DRIVEN
                    <br />
                    Humans are the most precious asset of the company. We
                    believe that business development must start with the
                    development of the capabilities and discipline of each
                    individual. Therefore, we always prioritize skilled,
                    enthusiastic, and willing-to-learn staff.
                  </li>
                  <li>
                    DEDICATION
                    <br />
                    Dedication is the guiding principle in all of our
                    activities. We always strive to ensure that every project
                    and task are carried out with the utmost dedication and
                    responsibility.
                  </li>
                  <li>
                    CREATIVITY
                    <br />
                    Creativity is a fundamental element that helps us create
                    effective and outstanding marketing solutions. We always
                    seek new approaches, implement advanced technology, and
                    create innovative ideas to meet the market's demands.
                  </li>
                  <li>
                    ATTEMPT
                    <br />
                    Effort is the spirit of never giving up. We see challenges
                    as opportunities to learn and conquer the seemingly
                    impossible.
                  </li>
                  <li>
                    COMPANIONSHIP
                    <br />
                    Companionship is understanding and sharing. We are always
                    ready to accompany our customers and partners on the shared
                    journey to achieve goals and success.
                  </li>
                </ul>
              </Text>
            </div>
            <div className="item pic">
              <div className="ratio">
                <img
                  src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-corevalues_b0uju0.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div
            id="thegame"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              height: "7vh",
            }}
          ></div>
        </SectionD>
        <MileStones line1="03" line2=".24" line3="NOSTALGIA" line4="GAME" />
        <GameA>
          <div className="divLeft">
            <div className="box">
              <div className="title">
                <div className="m-0 h4">
                  Defend
                  <h6 className="m-0">for</h6>
                </div>
                <h1>AIUR</h1>
              </div>
              <p>
                Embark on a journey back to the golden age of gaming with Aiur's
                classic arcade collection. Experience the thrill of retro gaming
                as we bring beloved titles from the past into the present.
              </p>
              <span>
                RELIVE THE ADVENTURES OF
                <br />
                YESTERYEARS
              </span>
              <TbArrowBadgeRightFilled />
            </div>
            <p className="text-outer">
              This mini-game is designed to resonate with the nostalgic feelings
              of the audience, invoking a sense of connection with the past and
              excitement for experiencing it anew with Aiur.
            </p>
          </div>
          <div className="divRight">
            <div className="title">
              <h6>Bringing Childhood</h6>
              Memories
            </div>
            <br />
            <button onClick={handleShow}>
              <OverlayBtn text="Play Now" />
            </button>
          </div>
        </GameA>
        <MileStones line1="04" line2=".24" line3="LOST" line4="SECTOR" />
        <GameB>
          <div className="content">
            <div className="h1">
              <h2>Abandoned</h2>
              PLANET
            </div>
            <p>
              Venture into The Endless Sector 99, a realm where the whispers of
              the cosmos speak of an enigmatic quadrant lost to time. Known
              among the starfarers as the sector where one may step in but never
              depart, it harbors secrets of civilizations that once flourished
              and now are mere echoes among the stars. Dare to discover the
              myths and legends that linger in this forsaken sector, where the
              bravest souls journey to uncover truths and challenge the saying
              that there is no way out
            </p>
            <button
              onClick={() => {
                if (startVidGame2Ref === false) {
                  // handleTurnOffAudio();
                  vidGame2Ref.current.play();
                }
              }}
              data-bs-toggle="modal"
              data-bs-target="#modalGameB"
            >
              <OverlayBtn text="Play Now" />
            </button>
          </div>
        </GameB>
      </Container>
    );
  };

export default ExploreTheCosmo;

const Container = styled.section`
  background: #00253a;
  padding-top: 4rem;
  @media screen and (min-width: 1024px) {
    padding-top: 3rem;
  }
`;
const SectionA = styled.div`
  position: relative;
  height: 55vw;
  background: url("https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-letter-bg_bpwngq.png");
  background-position: center bottom 30%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 1024px) {
    height: calc(100vh - 3rem);
  }
  > button {
    position: relative;
    text-shadow: 0px 0px 2px #fbbc04;
    font-family: Turret Road;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0;
    white-space: nowrap;
    padding: 0.5rem 1rem;
    border: 2px solid rgba(251, 188, 4, 0.7);
    border-radius: 1rem 0 1rem 0;
    font-size: 1rem;
    @media screen and (min-width: 768px) {
      font-size: 1.5rem;
    }
    &.verMobile {
      color: #7ddbf9;
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0.8) 100%
        ),
        #296d94;
      @media screen and (min-width: 1024px) {
        display: none;
      }
    }
    &.verDesktop {
      display: none;
      @media screen and (min-width: 1024px) {
        display: block;
        color: #fff;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0.5) 100%
          ),
          rgba(41, 109, 148, 0.5);
        &:hover {
          color: #7ddbf9;
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.8) 0%,
              rgba(0, 0, 0, 0.8) 100%
            ),
            #296d94;
        }
      }
    }
  }
  .scrollDown {
    position: absolute;
    right: 2rem;
    bottom: 1rem;
    text-align: center;
    display: none;
    @media screen and (min-width: 1024px) {
      display: block;
    }
    > h5 {
      color: #fff;
      filter: drop-shadow(0px 0px 6px #7a3eaf);
      font-family: "Turret Road";
      font-weight: bold;
      line-height: 1;
    }
    > svg {
      width: 2rem;
      height: 2rem;
      stroke-width: 1px;
      color: #aa9bff;
    }
  }
  .offcanvas-body {
    padding: 0;
  }
`;
const SectionB = styled.div`
  padding: 3rem 1rem;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.8) 100%
    ),
    url("https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-aboutus-bg_q05pw0.png")
      center center / cover;
  color: #fff;
  font-weight: 500;
  line-height: 1.5;
  clip-path: polygon(
    2rem 0,
    100% 0,
    100% calc(100% - 2rem),
    calc(100% - 2rem) 100%,
    0 100%,
    0 2rem
  );
  h6 {
    text-align: right;
    font-family: "Turret Road";
    font-weight: bold;
    line-height: 1.5;
  }
`;
const SectionC = styled.div`
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url(https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-ourcrew-bg_pypva7.png)
      center 93% / cover;
  padding: 2rem 0;
  @media screen and (min-width: 1024px) {
    padding: 2rem;
  }
  .wrap {
    background: rgba(0, 125, 188, 0.3);
    padding: 1rem 0;
    h5 {
      color: #7ddbf9;
      text-shadow: 0px 0px 10px #fbbc04;
      font-family: "Turret Road";
      line-height: 1.2;
      letter-spacing: 1px;
      margin: 0;
      padding: 0 1rem;
    }
    .crewListMobile {
      .swiper-pagination-bullet-active {
        background: #fbbc04;
      }
      padding: 2.5rem 0;
      .swiper-slide {
        height: 15rem;
        width: 12rem;
      }
      @media screen and (min-width: 1024px) {
        display: none;
      }
    }
    .crewListDesktop {
      display: none;
      @media screen and (min-width: 1024px) {
        display: flex;
        justify-content: flex-end;
        padding: 2.5rem 6rem;
      }
    }
  }
  > a {
    display: inline-block;
    margin: 3rem 0 0 1rem;
    padding: 1rem;
    border: 2px solid #fbbc04;
    line-height: 1;
    letter-spacing: 0.5px;
    border-radius: 0 1rem 1rem 1rem;
    color: #fbbc04;
    background: rgba(0, 0, 0, 0.7);
    @media screen and (min-width: 1024px) {
      color: rgba(251, 188, 4, 0.7);
      background: rgba(0, 0, 0, 0.4);
      &:hover {
        color: #fbbc04;
        background: rgba(0, 0, 0, 0.7);
      }
    }
  }
`;
const SectionD = styled.div`
  position: relative;
  padding: 0;
  @media screen and (min-width: 1024px) {
    padding: 0 3rem;
  }
  .article {
    display: flex;
    flex-direction: column;
    &:nth-of-type(2) {
      flex-direction: column-reverse;
      @media screen and (min-width: 1024px) {
        flex-direction: row;
      }
    }
    @media screen and (min-width: 1024px) {
      flex-direction: row;
    }
    .item {
      width: 100%;
      @media screen and (min-width: 1024px) {
        width: 50%;
      }
      &.para {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
        @media screen and (min-width: 1024px) {
          padding: 2rem;
        }
        .title {
          display: flex;
          gap: 0.5rem;
          color: #fff;
          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
          h3 {
            font-family: Turret Road;
            margin: 0;
          }
        }
      }
      &.pic {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @media screen and (min-width: 1024px) {
          justify-content: flex-start;
        }
        &.second {
          @media screen and (min-width: 1024px) {
            justify-content: flex-end;
          }
          img.second {
            clip-path: polygon(0 0, 90% 0, 100% 10%, 100% 100%, 0 100%);
          }
        }
        > .ratio {
          width: 80%;
          --bs-aspect-ratio: 100%;
          > img {
            display: block;
            object-fit: cover;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%, 0 90%);
          }
        }
      }
    }
  }
`;
const GameA = styled.div`
  position: relative;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
  &::before {
    content: "";
    height: 90%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: #fbbc04;
  }
  .divLeft {
    position: relative;
    width: 100%;
    font-family: Inter;
    @media screen and (min-width: 1024px) {
      width: 25rem;
    }
    .box {
      background: #47483b;
      padding: 1rem 2rem;
      clip-path: polygon(
        0 0,
        100% 0,
        100% 100%,
        2rem 100%,
        0 calc(100% - 2rem)
      );
      .title {
        text-align: center;
        filter: drop-shadow(0px 0px 2px #fbbc04);
        font-family: "Turret Road";
        line-height: 1;
        > div {
          color: white;
        }
        > h1 {
          color: #7ddbf9;
          font-weight: bold;
        }
      }
      p {
        color: #c2beae;
        line-height: 1.2;
      }
      span {
        color: #0ff;
        font-size: 0.7rem;
        font-weight: bold;
        line-height: 1;
        display: inline-flex;
        margin-right: 0.5rem;
      }
      svg {
        color: #fbbc04;
      }
    }
    .text-outer {
      color: #000;
      text-align: justify;
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 1.1;
      padding: 0.5rem 2rem;
    }
  }
  .divRight {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4rem;
    width: 100%;
    aspect-ratio: 1;
    @media screen and (min-width: 1024px) {
      width: 25rem;
    }
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 100%
      ),
      url(https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-game1-bg_v4vqrn.png)
        center / cover;
    .title {
      display: inline-block;
      color: #f5f2e7;
      text-align: right;
      font-family: "Turret Road";
      font-weight: 800;
      line-height: 1;
      letter-spacing: 1px;
      text-align: right;
      margin: 0;
      font-size: 3.6rem;
      > h6 {
        font-size: 1.4rem;
        margin-bottom: -1rem;
      }
    }
    button {
      display: none;
      @media screen and (min-width: 1024px) {
        display: inline-block;
      }
    }
  }
`;
const GameB = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem 1rem;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.3) 100%
    ),
    url(https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-game2-bg_ece5g0.png)
      center / cover;
  margin: 0;
  @media screen and (min-width: 1024px) {
    padding: 4rem;
  }
  .content {
    position: relative;
    font-family: "Turret Road";
    width: 30rem;
    > div {
      text-align: center;
      color: #7ddbf9;
      text-shadow: 0px 0px 2px #fbbc04;
      font-weight: bold;
      letter-spacing: 1px;
      font-size: 3rem;
      line-height: 1;
      h2 {
        color: #fff;
        font-size: 2rem;
        font-weight: normal;
        margin: 0;
      }
    }
    p {
      line-height: 1.2;
      color: #fbfeff;
      font-weight: 400;
      letter-spacing: 1px;
      margin: 0;
    }
    button {
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateX(calc(100% + 3rem));
      display: none;
      @media screen and (min-width: 1024px) {
        display: block;
      }
    }
  }
`;
const Text = styled.div`
  color: #fff;
  font-family: Turret Road;
  font-size: 1rem;
`;
const ContainerModalLetter = styled.div`
  color: #fff;
  letter-spacing: 1px;
  position: relative;
  text-align: center;
  clip-path: none;
  padding: 2rem 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: 1024px) {
    width: 33rem;
    height: 33rem;
    clip-path: polygon(0 0, 100% 0, 100% 95%, 95% 100%, 0 100%);
    padding: 0 1rem;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.85) 0%,
        rgba(0, 0, 0, 0.85) 100%
      ),
      url("https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/thecosmo-letter-bg_bpwngq.png")
        center bottom / cover;
    transform: scaleX(-1);
  }
  h1 {
    position: relative;
    text-shadow: 0px 0px 2px #fbbc04;
    font-family: "Turret Road";
    font-weight: 800;
  }
  h6 {
    position: relative;
    line-height: 1.4;
  }
  h5 {
    position: relative;
    font-weight: 700;
  }
`;
const ContainerModalCrew = styled.div`
  > div {
    width: 50rem;
    background: #47483b;
    padding: 3rem 2rem 2rem;
    display: flex;
    justify-content: space-around;
    clip-path: polygon(
      0 0,
      calc(100% - 3rem) 0,
      100% 3rem,
      100% 100%,
      3rem 100%,
      0 calc(100% - 3rem)
    );
    img {
      border: 3px solid rgba(251, 188, 4, 0.7);
      width: 45%;
    }
  }
`;
const ContainerModalGameFirst = styled.div`
  width: 88vh;
  height: 95vh;
  padding: 1em;
  background: bisque;
  text-align: center;
`;
const ContainerModalGameSecond = styled.div`
  width: 143vh;
  height: 90vh;
  text-align: center;
`;
