// font family
import "@fontsource/turret-road";
import "@fontsource/dm-sans";
import "@fontsource/inter";
import "@fontsource/roboto";
import "@fontsource/tulpen-one";
import "@fontsource/quicksand";
import CursorDefault from "../components/CursorDefault.jsx";
import CursorPointer from "../components/CursorPointer.jsx";

import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

:root {
  --swiper-theme-color: mintcream;
  --swiper-navigation-size: 28px !important;
  @media all and (min-width: 768px) {
    --swiper-navigation-size: 30px !important;
  }
  @media all and (min-width: 1024px) {
    --swiper-navigation-size: 40px !important;
  }
  --swiper-pagination-bullet-inactive-color: mintcream;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bottom: 0;
}
.swiper-button-prev, .swiper-button-next{
 cursor: url(${CursorPointer}), auto !important;
}
.offcanvas.offcanvas-bottom {
  height: auto;
  .offcanvas-header{
    padding: 0.5rem 1rem;
  }
}
.navbar-toggler{
  border: none;
  &:focus{
    box-shadow: none;
  }
}
.btn-close{
  background: #fff var(--bs-btn-close-bg) center/1em auto no-repeat;
  opacity: 1;
  border-radius: 50%;
  &:focus{
    box-shadow: none;
  }
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: initial !important;
  scrollbar-width: thin;
}
html,
body {
  background: #1a1a1a;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: calc(15px + 0.390625vw);
  cursor: url(${CursorDefault}), auto;
}
.modalOurStory{
  color: #fff;
  background: transparent;
  >h5{
    position: relative;
    color: #fbbc04;
    text-align: center;
  }
  > p{
    position: relative;
    margin: 1rem 0 0;
  }
}
.modal.fade.show {
  scrollbar-width: none;
}
.modal-dialog {
  max-width: fit-content !important;
}
.modal-content{
  border: none !important;
  background: transparent !important;
}
.fade {
    transition: opacity 0s !important;
}
a {
  text-decoration: none !important;
  cursor: url(${CursorPointer}), auto !important;
}
ul {
  list-style: none;
  margin: 0;
}
button {
  cursor: url(${CursorPointer}), auto !important;
  background: none;
  border: none;
}

.serviceItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border: 3px solid #007DBC;
  }
  .crewText{
    text-align: center;
    color: #fff;
  h4 {
    text-shadow: 0px 0px 2px #fbbc04;
    font-family: "Turret Road";
    font-weight: 600;
    line-height: 1;
    letter-spacing: 1px;
  }
  h6 {
    font-family: Inter;
    color: #7ddbf9;
    white-space: nowrap;
  }
  p {
    text-align: left;
    font-family: Inter;
    line-height: 1.4;
    letter-spacing: -0.264px;
    padding-top: 1rem;
    margin: 0;
  }
  button {
    color: #fbbc04;
    text-shadow: 0px 0px 2px #fbbc04;
    font-family: "Turret Road";
    font-weight: 500;
    padding: 0.5rem 1rem;
    background: rgb(32, 125, 155);
    width: 100%;
    white-space: nowrap;
  }
  }
`;

export default GlobalStyles;
